import React from 'react';

import * as Styled from './styled';
import { getOneLink } from '../../utils/oneLink';

export const InstallBanner = () => (
    <Styled.Container>
        <Styled.Logo alt="My Drama logo" src="/img/app-logo.webp" />
        <Styled.Button as="button" onClick={getOneLink}>Get app</Styled.Button>
    </Styled.Container>
)