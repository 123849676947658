import styled from "styled-components";

import { ReactComponent as Star } from '../../../assets/svg/star.svg'

export const Container = styled.section`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    scroll-margin-top: 100px;

    @media screen and (max-width: 550px) {
        height: fit-content;
    }
`;

export const StarIcon = styled(Star)`
    align-self: flex-start;
    margin-bottom: 16px;

    @media screen and (max-width: 550px) {
        width: 30px;
        height: 30px;
    }
`;

export const TopContainer = styled.div`
    width: 100%;
    display: grid;
    grid-template-columns: 0.6fr 0.44fr;
    gap: 49px;

    @media screen and (max-width: 1024px) {
      max-width: 615px;
      align-self: flex-start;
      grid-template-columns: 1fr;
      gap: 36px;
    }
`;

export const Heading = styled.h2`
    font-weight: 700;
    font-size: 72px;
    line-height: 80px;
    letter-spacing: -0.04em;

    color: #ECECEC;

    @media screen and (max-width: 1050px) {
        font-size: 56px;
        line-height: 66px;
    }

    @media screen and (max-width: 800px) {
        font-size: 40px;
        line-height: 112%;
    }

    @media screen and (max-width: 700px) {
        margin-right: 0;
        font-size: 38px;
        line-height: 112%;
    }
`;

export const ContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
`;

export const Description = styled.p`
    font-size: 20px;
    line-height: 1.7;

    color: #ECECEC;

    @media screen and (max-width: 700px) {
        width: 100%;
    }

    @media screen and (max-width: 550px) {
        font-size: 16px;
        line-height: 150%;
    }
`;

export const Button = styled.button`
    text-decoration: none;
    padding: 14px 32px;
    border-radius: 10px;
    background-color: #4500FF;
    margin-right: ${({ mr }) => `${(mr || 0)}px`};
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.02em;

    color: #FFFFFF;
    border: none;

    @media screen and (min-width: 1024px) {
      transition: background-color 0.25s ease;
  
      &:hover {
        background-color: #3100b5;
      }
    }

    @media screen and (max-width: 700px) {
        padding: 12px 20px;
    }
`;

export const Banner = styled.img`
    max-width: 1400px;
    pointer-events: none;
    margin-top: -180px;

    @media screen and (max-width: 800px) {
      transform: translateX(-30px);
      max-width: calc(100vw + 450px);
      margin-top: -100px;
    }
`;


