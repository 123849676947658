import React from 'react';
import { Link } from 'react-router-dom';
import { HeaderMobile } from './header-mobile';

import * as Styled from './styled'
// import { getOneLink } from '../../utils/oneLink';

export const Header = () => {
    return (
        <>
            <Styled.Container>
                <Styled.TopBar />
                <Styled.Wrapper>
                    <Styled.Content>
                        <Link to="/" aria-label="Home">
                          <Styled.Logo />
                        </Link>
                        <Styled.Navigation>
                            <a href="#contact-us">
                                <Styled.Button mr={12}>Partnership inquiries</Styled.Button>
                            </a>

                            <a href="#publish">
                                <Styled.Button accent>Publish your content</Styled.Button>
                            </a>
                            <Styled.Stores>
                                <a aria-label="Apple Store" href='http://apps.apple.com/us/app/id6498713494?mt=8' style={{backgroundColor: "transparent", border: "none"}} >
                                    <Styled.AppleStore fill="#ffffff" />
                                </a>
                                <a aria-label="Google Store" href='https://play.google.com/store/apps/details?id=com.dashdramas&hl=en&gl=US' style={{backgroundColor: "transparent", border: "none"}} >
                                    <Styled.GoogleStore />
                                </a>
                            </Styled.Stores>
                        </Styled.Navigation>
                    </Styled.Content>
                </Styled.Wrapper>
            </Styled.Container>
            <HeaderMobile />
        </>
    )
}