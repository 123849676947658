import styled from "styled-components";

import { ReactComponent as AppLogo } from '../../assets/svg/logo.svg'
import { ReactComponent as AppleStoreLogo } from '../../assets/svg/apple.svg'
import { ReactComponent as GoogleStoreLogo } from '../../assets/svg/google.svg'

export const Container = styled.footer`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 77px;
    width: 100%;
    height: 173px;

    @media screen and (max-width: 550px) {
        height: fit-content;
        padding-bottom: 106px;
    }
`

export const Wrapper = styled.div`
    display: flex;
    width: 100%;
    justify-content: space-between;

    @media screen and (max-width: 550px) {
        flex-direction: column;
        align-items: center;
    }
`;

export const LeftBlock = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @media screen and (max-width: 550px) {
        align-items: center;
    }
`

export const Logo = styled(AppLogo)`
    @media screen and (max-width: 550px) {
        width: 78px;
        height: 17px;
        margin-bottom: 30px;
    }
`

export const StoreButton = styled.button`
    margin-right: 20px;
    display: flex;
    padding: 12px 32px;
    min-width: 190px;
    text-decoration: none;

    align-items: center;
    justify-content: center;

    border-radius: 99999px;

    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.02em;

    color: #0A0A0F;
    background: #ECECEC;
    border: none;

    @media screen and (min-width: 1024px) {
      transition: background-color 0.25s ease;
  
      &:hover {
        background-color: #ffffff;
      }
    }

    @media screen and (max-width: 650px) {
        min-width: 166px;
        padding: 12px 20px;
        margin-right: 10px;
    }

    @media screen and (max-width: 550px) {
        margin-right: 0;
        margin-bottom: 16px;
        
    }
`

export const Stores = styled.div`
    display: flex;
    align-items: center;

    @media screen and (max-width: 550px) {
        flex-direction: column;
        align-items: center;
        margin-bottom: 20px;
    }
`

export const AppleStore = styled(AppleStoreLogo)`
    width: 24px;
    height: 24px;
    margin-top: -4px;
    margin-right: 13px;
`;

export const GoogleStore = styled(GoogleStoreLogo)`
    margin-right: 13px;
    width: 24px;
    height: 24px;
`;

export const RightsText = styled.span`
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 0.02em;

    color: #64738A;

    @media screen and (max-width: 550px) {
        display: none;
    }
`;

export const RightBlock = styled.div`
    display: flex;
    flex-direction: column;

    @media screen and (max-width: 550px) {
        align-items: center;
    }
`

export const GetAppText = styled.span`
    margin-bottom: 18px;
    font-size: 20px;
    line-height: 32px;

    color: #BCC4D0;

    @media screen and (max-width: 550px) {
        font-size: 16px;
        line-height: 150%;
        color: #64738A;
    }
`

export const Year = styled(RightsText)`
    transform: translateY(-100%);

    @media screen and (max-width: 950px) {
        transform: none;
        align-self: flex-start;
    }
`

export const MobileBlock = styled.div`
    display: none;

    @media screen and (max-width: 550px) {
        width: 100%;
        display: flex;
        align-items: center;
        flex-direction: column;
    }
`;

export const Email = styled.a`
    margin-bottom: 36px;
    font-size: 16px;
    line-height: 150%;
    text-decoration: none;

    color: #BCC4D0;
`;

export const Terms = styled.div`
    margin-bottom: 8px;
    display: flex;
    align-items: center;
`;

export const Term = styled.a`
    text-decoration: none;
    font-size: 13px;
    line-height: 160%;
    letter-spacing: 0.02em;

    color: #64738A;
`

export const TermDivider = styled.div`
    margin: 0px 12px;

    width: 4px;
    height: 4px;
    background: #64738A;
    border-radius: 9999px;
`;

export const RightsReserved = styled.span`
    font-size: 13px;
    line-height: 160%;
    text-align: center;
    letter-spacing: 0.02em;

    color: #64738A;
`;
