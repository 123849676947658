import React from "react";

import * as Styled from './styled';

export const ContactUs = () => (
    <Styled.Container id="contact-us">
        <Styled.Wrapper>
            <Styled.BackgroundGradient alt="" src="/img/contact-us-bg.webp" />
            <Styled.Image alt="Rocket" src="/img/rocket.webp" />
            <Styled.TextBlock>
                <Styled.Heading id="#contact-us">Let's partner and engage users in Vertical Streaming together!</Styled.Heading>
                <Styled.Text>Drop us an email and tell us more about partnership opportunity you are interested in </Styled.Text>
                <a href="mailto:partnerships@my-drama.com">
                    <Styled.Button>Contact Us</Styled.Button>
                </a>
            </Styled.TextBlock>
        </Styled.Wrapper>
    </Styled.Container>
)