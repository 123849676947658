import styled from "styled-components";

export const Container = styled.section`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    
    @media screen and (max-width: 550px) {
        height: fit-content;
        min-height: 0px;
        padding-top: 90px;
    }
`;

export const Title = styled.h2`
    margin-bottom: 80px;
    align-self: flex-start;

    font-weight: 700;
    font-size: 72px;
    line-height: 80px;
    letter-spacing: -0.04em;

    color: #ECECEC;

    @media screen and (max-width: 800px) {
      font-size: 48px;
      margin-bottom: 60px;
    }

    @media screen and (max-width: 550px) {
        text-align: center;
        font-size: 38px;
        line-height: 112%;
        margin-bottom: 52px;
    }
`;

export const ImagesContainer = styled.div`
    width: 100%;
    position: relative;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 32px 40px;

    @media screen and (max-width: 1024px) {
      grid-template-columns: repeat(2, 1fr);
      gap: 24px 32px;
    }

    @media screen and (max-width: 768px) {
      gap: 16px;
    }
`

export const BlurBackground = styled.div`
    position: absolute;
    top: 17%; 
    left: 17%; 
    right: 17%; 
    bottom: 20%;
    background-color: #6006BA;
    opacity: 0.2;
    z-index: 0;
    filter: blur(140px);
 `;

export const ImageWrapper = styled.div`
    position: relative;
    z-index: 1;

    @media screen and (min-width: 1024px) {
      transition: transform 0.25s ease;

      &:hover {
        transform: scale(1.05);
      }
    }
`;

export const Image = styled.img`
    width: 100%;
    object-fit: cover;
    overflow: hidden;
    border-radius: 20px;

    @media screen and (max-width: 550px) {
        border-radius: 8px;
    }
`;