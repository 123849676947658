import styled from 'styled-components';

export const Container = styled.div`
    position: fixed;
    bottom: 16px;
    display: none;
    width: calc(100% - 24px);
    padding: 12px 15px;
    background: rgba(11, 13, 33, 0.9);
    border-radius: 16px;
    align-items: center;
    z-index: 999999;
    transform: translate(0px, 0px);

    @media screen and (max-width: 820px) {
        display: flex;
        justify-content: space-between;
    }
`;

export const Logo = styled.img`
    width: 185px;
    height: auto;
`;

export const Button = styled.button`
    width: fit-content;

    padding: 12px 20px;
    border-radius: 10px;
    background-color: #4500FF;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.02em;
    text-decoration: none;

    color: #FFFFFF;
    border: none;
`