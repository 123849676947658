import { createBrowserRouter, Navigate } from "react-router-dom";
import { ScrollToTop } from "../components/scroll-to-top";
import { HomePage } from "./home";

export const router = createBrowserRouter([
    {
        path: '/',
        element:  <ScrollToTop><HomePage /></ScrollToTop>,
    },
     {
        path: '*',
        element: <Navigate to='/' />
     }
])