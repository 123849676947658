import React from "react";

import * as Styled from './styled'

export const Intro = () => {

    return (
        <Styled.Wrapper>
            <Styled.Container>
                <Styled.ContentContainer>
                    <Styled.Heading>MyDrama is looking for partners</Styled.Heading>
                    <Styled.Subheading>We build personalized worlds for 20+ million users by combining creators’ imagination with AI’s efficiency</Styled.Subheading>
                    <Styled.ButtonsContainer>
                        <Styled.StoreButton as="a" href='http://apps.apple.com/us/app/id6498713494?mt=8'>
                            <Styled.AppleStore fill="#0A0A0F" />
                            App Store
                        </Styled.StoreButton>
                        <Styled.StoreButton as="a" href='https://play.google.com/store/apps/details?id=com.dashdramas&hl=en&gl=US'>
                            <Styled.GoogleStore />
                            Google Play
                        </Styled.StoreButton>
                    </Styled.ButtonsContainer>
                </Styled.ContentContainer>

                <Styled.SideImage alt="My Drama app" src="/img/new-intro.webp" loading="lazy" />
                <Styled.MobileImage alt="My Drama app" src="/img/new-intro-mobile.webp" loading="eager" />
            </Styled.Container>
        </Styled.Wrapper>
    )
}