import styled from "styled-components";

// TODO: add separate component
export const Container = styled.section`
    padding: 180px 0;
    width: 100%;

    @media screen and (max-width: 550px) {
        min-height: 0;
        padding: 100px 0;
    }
`;

export const Wrapper = styled.div`
    width: 100%;
    display: grid;
    grid-template-columns: 0.4fr 1fr;
    gap: 87px;
    align-items: center;
    position: relative;

    @media screen and (max-width: 1024px) {
        grid-template-columns: 1fr;
        gap: 60px;
    }
`;

export const BackgroundGradient = styled.img`
    width: 100%;
    object-fit: cover;
    position: absolute;
    transform: translateY(-100px) scale(1.4);
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 0;
    pointer-events: none;

    @media screen and (max-width: 1024px) {
      transform: translateY(200px) scale(1.5) rotate(90deg);
    }
`;

export const Image = styled.img`
    width: 100%;
    object-fit: cover;
    position: relative;
    z-index: 1;

    @media screen and (max-width: 1024px) {
      max-width: 400px;
      margin: 0 auto;
    }

    @media screen and (max-width: 550px) {
      max-width: 250px;
    }
`;

export const TextBlock = styled.div`
    width: 100%;
    padding: 56px 59px;
    display: flex;
    flex-direction: column;
    background: #ECECEC;
    border-radius: 20px;
    position: relative;
    z-index: 1;

    @media screen and (max-width: 1024px) {
      max-width: 800px;
      margin: 0 auto;
    }

    @media screen and (max-width: 768px) {
        padding: 48px 24px;
        align-items: center;
    }

    @media screen and (max-width: 550px) {
        padding: 32px 16px;
        align-items: center;
    }
`;

export const Heading = styled.h2`
    margin-bottom: 24px;
    font-weight: 800;
    font-size: 54px;
    line-height: 64px;

    color: #0A0A0F;

    @media screen and (max-width: 768px) {
        text-align: center;
        margin-bottom: 28px;
        font-size: 38px;
        line-height: 112%;
    }

    @media screen and (max-width: 550px) {
      font-size: 32px;
    }
`;

export const Text = styled.p`
    margin-bottom: 40px;
    font-size: 22px;
    line-height: 33px;

    color: #0A0A0F;

    @media screen and (max-width: 768px) {
        text-align: center;
        margin-bottom: 48px;
        line-height: 150%;
    }
`;

export const Button = styled.button`
    min-width: 213px;
    text-decoration: none;
    padding: 14px 32px;
    border-radius: 10px;
    background-color: #4500FF;
    margin-right: ${({ mr }) => `${(mr || 0)}px`};
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.02em;

    color: #FFFFFF;
    border: none;

    @media screen and (min-width: 1024px) {
      transition: background-color 0.25s ease;
  
      &:hover {
        background-color: #3100b5;
      }
    }

    @media screen and (max-width: 768px) {
        padding: 12px 20px;
    }
`;