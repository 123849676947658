import styled from "styled-components";
import { ReactComponent as AppLogo } from '../../assets/svg/logo.svg'
import { ReactComponent as AppleStoreLogo } from '../../assets/svg/apple.svg'
import { ReactComponent as GoogleStoreLogo } from '../../assets/svg/google.svg'


export const Container = styled.div`
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;

    z-index: 99999;

    @media screen and (max-width: 850px) {
        display: none;
    }
`

export const TopBar = styled.div`
    width: 100%;
    height: 20px;
    background: #0A0A0F;
`

export const Wrapper = styled.div`
    display: flex;
    justify-content: center;
    padding-bottom: 80px;

    background: linear-gradient(180deg, #0A0A0F 0%, rgba(10, 10, 15, 0.991353) 6.67%, rgba(10, 10, 15, 0.96449) 13.33%, rgba(10, 10, 15, 0.91834) 20%, rgba(10, 10, 15, 0.852589) 26.67%, rgba(10, 10, 15, 0.768225) 33.33%, rgba(10, 10, 15, 0.668116) 40%, rgba(10, 10, 15, 0.557309) 46.67%, rgba(10, 10, 15, 0.442691) 53.33%, rgba(10, 10, 15, 0.331884) 60%, rgba(10, 10, 15, 0.231775) 66.67%, rgba(10, 10, 15, 0.147411) 73.33%, rgba(10, 10, 15, 0.0816599) 80%, rgba(10, 10, 15, 0.03551) 86.67%, rgba(10, 10, 15, 0.0086472) 93.33%, rgba(10, 10, 15, 0) 100%);
`

export const Content = styled.div`
    width: 100%;
    max-width: 1200px;
    padding: 0 40px;

    display: flex;
    justify-content: space-between;

    @media screen and (max-width: 700px) {
        padding: 0 20px;
    }
`

export const Logo = styled(AppLogo)`
    margin-top: 13px;
`

export const Navigation = styled.div`
    display: flex;
    align-items: center;
`

export const Button = styled.button`
    text-decoration: none;
    padding: 14px 32px;
    border-radius: 10px;
    background-color: ${({ accent }) => accent ? '#4500FF' : 'transparent'};
    margin-right: ${({ mr }) => `${(mr || 0)}px`};
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.02em;

    color: #FFFFFF;
    border: none;

    @media screen and (min-width: 1024px) {
      transition: background-color 0.25s ease, text-decoration 0.25s ease;

      &:hover {
        background-color: ${({ accent }) => accent ? '#3100b5' : 'transparent'};
        text-decoration: ${({ accent }) => !accent ? 'underline' : 'none'};
        text-underline-offset: ${({ accent }) => !accent ? '5px' : '0'};
      }
    }

    @media screen and (max-width: 700px) {
        padding: 12px 20px;
    }
`

export const Stores = styled.div`
    display: flex;
    align-items: center;
`

export const AppleStore = styled(AppleStoreLogo)`
    margin-right: 40px;
    margin-left: 50px;

    @media screen and (min-width: 1024px) {
      transition: transform 0.25s ease;
  
      &:hover {
        transform: scale(1.15);
      }
    }

    @media screen and (max-width: 700px) {
        margin-right: 20px;
        margin-left: 30px;
    }
`;

export const GoogleStore = styled(GoogleStoreLogo)`
    width: 24px;
    height: 24px;
    margin-top: 4px;

    @media screen and (min-width: 1024px) {
      transition: transform 0.25s ease;
  
      &:hover {
        transform: scale(1.15);
      }
    }
`;