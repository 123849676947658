import styled from "styled-components";
import { Link as RouterLink } from "react-router-dom";

import { ReactComponent as LogoIcon } from "../../../assets/svg/logo.svg";
import { ReactComponent as BurgerIcon } from "../../../assets/svg/burger.svg";
import { ReactComponent as AppleStoreLogo } from '../../../assets/svg/apple.svg'
import { ReactComponent as GoogleStoreLogo } from '../../../assets/svg/google.svg'
import { ReactComponent as CrossIcon } from '../../../assets/svg/cross.svg'

export const AppleStore = styled(AppleStoreLogo)`
    margin-top: -5px;
    margin-right: 13px;
`;

export const GoogleStore = styled(GoogleStoreLogo)`
    margin-right: 13px;
    width: 24px;
    height: 24px;
`;

export const Container = styled.header`
    position: fixed;
    align-items: center;
    justify-content: center;
    display: none;
    z-index: 99999;
    width: 100%;

    @media screen and (max-width: 850px) {
        display: flex;
    }
`;

export const Wrapper = styled.div`
    padding: 15px 0;
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const Shadow = styled.div`
    height: 104px;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    background: linear-gradient(180deg, #0A0A0F 0%, rgba(10, 10, 15, 0.991353) 6.67%, rgba(10, 10, 15, 0.96449) 13.33%, rgba(10, 10, 15, 0.91834) 20%, rgba(10, 10, 15, 0.852589) 26.67%, rgba(10, 10, 15, 0.768225) 33.33%, rgba(10, 10, 15, 0.668116) 40%, rgba(10, 10, 15, 0.557309) 46.67%, rgba(10, 10, 15, 0.442691) 53.33%, rgba(10, 10, 15, 0.331884) 60%, rgba(10, 10, 15, 0.231775) 66.67%, rgba(10, 10, 15, 0.147411) 73.33%, rgba(10, 10, 15, 0.0816599) 80%, rgba(10, 10, 15, 0.03551) 86.67%, rgba(10, 10, 15, 0.0086472) 93.33%, rgba(10, 10, 15, 0) 100%);
`;

export const Logo = styled(LogoIcon)`
    z-index: 100;
    transform: translate(0, 0)
`;

export const MenuButton = styled.button`
    position: absolute;
    left: 25px;
    top: 50%;
    transform: translateY(-50%);
    border: none;
    background: transparent;

    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
`;

export const Burger = styled(BurgerIcon)``;

export const Drawer = styled.div`
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: ${({ expanded }) => expanded ? '0px' : '-105%'};
    display: none;

    transition: 400ms ease-in;

    z-index: 99999;

    background: rgba(10, 10, 15, 0.7);

    @media screen and (max-width: 850px) {
        display: block;
    }
`

export const DrawerContent = styled.div`
    padding: 24px;
    width: 100%;
    background: #0A0A0F;
`;

export const Navigation = styled.nav`
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
`;

export const Link = styled(RouterLink)`
    margin-bottom: 20px;
    font-weight: 600;
    font-size: 28px;
    line-height: 128%;
    letter-spacing: -0.02em;
    text-decoration: none;

    color: #ECECEC;
`;

export const Text = styled.p`
    margin-bottom: 16px;

    font-size: 16px;
    line-height: 150%;

    color: #64738A;
`;

export const DrawerShadow = styled.div`
    height: 200px;
    background: linear-gradient(180deg, #0A0A0F 0%, rgba(10, 10, 15, 0.991353) 6.67%, rgba(10, 10, 15, 0.96449) 13.33%, rgba(10, 10, 15, 0.91834) 20%, rgba(10, 10, 15, 0.852589) 26.67%, rgba(10, 10, 15, 0.768225) 33.33%, rgba(10, 10, 15, 0.668116) 40%, rgba(10, 10, 15, 0.557309) 46.67%, rgba(10, 10, 15, 0.442691) 53.33%, rgba(10, 10, 15, 0.331884) 60%, rgba(10, 10, 15, 0.231775) 66.67%, rgba(10, 10, 15, 0.147411) 73.33%, rgba(10, 10, 15, 0.0816599) 80%, rgba(10, 10, 15, 0.03551) 86.67%, rgba(10, 10, 15, 0.0086472) 93.33%, rgba(10, 10, 15, 0) 100%);
`;

export const StoreButton = styled.button`
    display: flex;
    width: fit-content;
    padding: 12px 32px;
    text-decoration: none;
    min-width: 190px;

    align-items: center;
    justify-content: flex-start;

    border-radius: 99999px;

    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.02em;

    color: #0A0A0F;
    background: #ECECEC;
    border: none;

    &:not(:last-child) {
        margin-bottom: 16px;
    }
`

export const CloseButton = styled.button`
    margin-bottom: 46px;
    border: none;
    background: transparent;

    display: flex;
    align-items: center;
    justify-content: center;
`;

export const Cross = styled(CrossIcon)``;