import React, { useEffect } from 'react';
import { Footer } from '../../components/footer';
import { Header } from '../../components/header';
import { InstallBanner } from '../../components/install-banner';
import { Layout, ContentContainer } from '../../components/layout';
import { Possibilities } from '../../components/sections/possibilities';
import { Intro } from '../../components/sections/intro';
import { ContactUs } from '../../components/sections/contact-us';
import { Publish } from '../../components/sections/publish';
import { TopStories } from '../../components/sections/top-stories';
import { MyDrama } from '../../components/sections/my-drama';
import { v4 as uuidv4 } from 'uuid';

export const HomePage = () => {
    useEffect(() => {
		const { search } = window.location
		const params = new URLSearchParams(search)
		const ttclid = params.get('ttclid')

		if (ttclid) {
			localStorage.setItem('ttclid', ttclid)
		}
	}, [])

	useEffect(() => {
		const didRedirect = sessionStorage.getItem('didRedirect') === '1'
		if (!didRedirect) {
			sessionStorage.setItem('didRedirect', '1')
			window.open(window.location.href, '_top', 'noopener,noreferrer')
		}
	}, [])
    useEffect(() => {
		const prevUserId = localStorage.getItem('amp-user-id')
		window.id=prevUserId
		if (!prevUserId) {
			const userId = uuidv4()
			localStorage.setItem('amp-user-id', userId)
			window.id = userId
		}
	}, [])

	useEffect(() => {
		window.history.scrollRestoration = 'manual'
	}, [])

    return (
        <Layout>
            <Header />
            <ContentContainer>
                <Intro />
                <MyDrama />
                <Possibilities />
                <Publish />
                <TopStories />
                <ContactUs />
                <Footer />
            </ContentContainer>
            <InstallBanner />
        </Layout>
    )
}