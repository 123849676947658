import styled from "styled-components";

import { ReactComponent as StatUser } from '../../../assets/svg/stats-icon-1.svg'
import { ReactComponent as StatSeries } from '../../../assets/svg/stats-icon-2.svg'
import { ReactComponent as StatGrowth } from '../../../assets/svg/stats-icon-3.svg'

export const Container = styled.section`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    @media screen and (max-width: 550px) {
        height: fit-content;
    }
`;

export const Heading = styled.h2`
  font-weight: 700;
  font-size: 72px;
  line-height: 80px;
  letter-spacing: -0.04em;
  color: #ECECEC;
  margin-bottom: 46px;
  align-self: flex-start;

  @media screen and (max-width: 800px) {
      font-size: 48px;
      line-height: 112%;
  }

  @media screen and (max-width: 550px) {
      margin-bottom: 36px;
      width: 100%;
      font-size: 38px;
      line-height: 112%;
  }
`;

export const BannerImage = styled.img`
  width: 100%;
  transform: translate(-18px, -85px) scale(1.35);
  pointer-events: none;

  @media screen and (max-width: 1024px) {
      min-width: 1024px;
  }

  @media screen and (max-width: 550px) {
      width: calc(100% + 400px);
  }
`;

export const StatsBlock = styled.div`
  margin-top: -190px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 124px;

  @media screen and (max-width: 1024px) {
    gap: 70px;
  }

  @media screen and (max-width: 768px) {
    gap: 40px;
  }

  @media screen and (max-width: 550px) {
    margin-top: -170px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;

export const StatItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const StatImageUser = styled(StatUser)`
  margin-bottom: 8px;
`;

export const StatStatSeries = styled(StatSeries)`
  margin-bottom: 8px;
`;

export const StatStatGrowth = styled(StatGrowth)`
  margin-bottom: 8px;
`;

export const StatHeading = styled.h3`
  color: #fff;
  font-size: 40px;
  font-weight: 700;
  margin-bottom: 16px;
`;

export const StatText = styled.p`
  color: #fff;
  text-align: center;
  font-size: 20px;
  font-weight: 500;
`;
