import styled from "styled-components";

import { ReactComponent as AppleStoreLogo } from '../../../assets/svg/apple.svg'
import { ReactComponent as GoogleStoreLogo } from '../../../assets/svg/google.svg'

export const Wrapper = styled.section`
    width: 100%;
    padding-top: 100px;
    min-height: 100vh;
    
    display: flex;
    justify-content: center;
    align-items: center;

    @media screen and (max-width: 820px) {
        align-items: flex-start;
        padding-top: 100px;
        padding-bottom: 80px;
        height: fit-content;
    }
`;

export const Container = styled.div`
    position: relative;
    width: 100%;

    @media screen and (max-width: 820px) {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
`;

export const ContentContainer = styled.div`
    width: 50%;

    @media screen and (max-width: 1050px) {
      width: 45%;
    }

    @media screen and (max-width: 820px) {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
`;

export const Heading = styled.h1`
    width: 100%;
    margin-bottom: 16px;
    font-weight: 800;
    font-size: 55px;
    line-height: 103%;

    color: #ECECEC;

    @media screen and (max-width: 1050px) {
      font-size: 40px;
    }

    @media screen and (max-width: 820px) {
        font-size: 44px;
        line-height: 112%;
        margin-bottom: 16px;
        max-width: 450px;
        text-align: center;
    }
`;

export const Subheading = styled.span`
    font-size: 33px;
    line-height: 1.2;
    font-weight: 400;

    color: #ECECEC;

    @media screen and (max-width: 1050px) {
      font-size: 24px;
    }

    @media screen and (max-width: 820px) {
        width: 100%;
        font-size: 20px;
        line-height: 142%;
        text-align: center;
    }
`;

export const ButtonsContainer = styled.div`
    margin-top: 40px;
    display: flex;
    align-items: center;

    @media screen and (max-width: 950px) {
        align-items: flex-start;
        flex-direction: column;
    }

    @media screen and (max-width: 820px) {
        display: none;
    }
`;

export const StoreButton = styled.button`
    z-index: 999999;
    margin-right: 20px;
    display: flex;
    padding: 12px 32px;
    min-width: 190px;
    text-decoration: none;

    align-items: center;
    justify-content: center;

    border-radius: 99999px;

    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.02em;
    color: #0A0A0F;
    background: #ECECEC;
    border: none;
    
    @media screen and (min-width: 1024px) {
      transition: background-color 0.25s ease;
  
      &:hover {
        background-color: #ffffff;
      }
    }
    
    @media screen and (max-width: 950px) {
      margin-bottom: 16px;
    }
`;

export const AppleStore = styled(AppleStoreLogo)`
    margin-right: 13px;
`;

export const GoogleStore = styled(GoogleStoreLogo)`
    margin-right: 13px;
    width: 24px;
    height: 24px;
`;

export const SideImage = styled.img`
    position: absolute;
    top: 60%;
    left: 30%;
    width: 70vw;
    max-width: 1050px;
    transform: translateY(-50%);

    @media screen and (min-width: 1601px) {
        transform: translateY(-50%) scale(1.2) translateX(10px);
    }

    @media screen and (max-width: 1250px) {
      width: 75vw;
      left: 35%;
    }

    @media screen and (max-width: 1050px) {
      width: 85vw;
      left: 25%;
    }

    @media screen and (max-width: 820px) {
        display: none;
    }
`;

export const MobileImage = styled.img`
    transform: translate(50px, 130px) scale(1.6);
    min-width: 690px;

    display: none;
    width: 100%;


    @media screen and (max-width: 820px) {
        display: block;
    }

    @media screen and (max-width: 450px) {
      transform: translate(50px, 70px) scale(1.6);
      min-width: 600px;
    }
`;