import React from "react";

import * as Styled from './styled'

export const Footer = () => {
    return (
        <Styled.Container>
            <Styled.Wrapper>
                <Styled.LeftBlock>
                    <Styled.Logo />
                    <Styled.RightsText>All rights reserved</Styled.RightsText>
                </Styled.LeftBlock>
                <Styled.RightBlock>
                    <Styled.GetAppText id="get-app">Get App</Styled.GetAppText>
                    <Styled.Stores>
                        <Styled.StoreButton as="a" href='http://apps.apple.com/us/app/id6498713494?mt=8'>
                            <Styled.AppleStore fill="#0A0A0F" />
                            App Store
                        </Styled.StoreButton>
                        <Styled.StoreButton as="a" href='https://play.google.com/store/apps/details?id=com.dashdramas&hl=en&gl=US'>
                            <Styled.GoogleStore />
                            Google Play
                        </Styled.StoreButton>
                    </Styled.Stores>
                </Styled.RightBlock>
    
                <Styled.MobileBlock>
                    <Styled.Email href="mailto:partnerships@my-drama.com">partnerships@my-drama.com</Styled.Email>
                    <Styled.Terms>
                        <Styled.Term href="#!">Terms Of Use</Styled.Term>
                        <Styled.TermDivider />
                        <Styled.Term href="#!">Terms Of Use</Styled.Term>
                    </Styled.Terms>
    
                    <Styled.RightsReserved>All rights reserved 2024</Styled.RightsReserved>
                </Styled.MobileBlock>
            </Styled.Wrapper>
            <Styled.Year>2024</Styled.Year>
        </Styled.Container>
    )
}