import React from "react";

import * as Styled from './styled';

export const MyDrama = () => (
    <Styled.Container>
      <Styled.Heading>My Drama</Styled.Heading>
      <Styled.BannerImage alt="My Drama app" src="/img/new-stories-library-banner.webp" />
      <Styled.StatsBlock>
        <Styled.StatItem>
          <Styled.StatImageUser />
          <Styled.StatHeading>1M+</Styled.StatHeading>
          <Styled.StatText>Monthly Active Users</Styled.StatText>
        </Styled.StatItem>

        <Styled.StatItem>
          <Styled.StatStatSeries />
          <Styled.StatHeading>30+</Styled.StatHeading>
          <Styled.StatText>Unique series</Styled.StatText>
        </Styled.StatItem>

        <Styled.StatItem>
          <Styled.StatStatGrowth />
          <Styled.StatHeading>Top 10</Styled.StatHeading>
          <Styled.StatText>Vertical Series app in US</Styled.StatText>
        </Styled.StatItem>
      </Styled.StatsBlock>
    </Styled.Container>
)