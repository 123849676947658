import React, { useState } from 'react'
import { Link } from 'react-router-dom';

import * as Styled from './styled';
// import { getOneLink } from '../../../utils/oneLink';

export const HeaderMobile = () => {
    const [isOpen, setIsOpen] = useState(false);

    return (
        <>
            <Styled.Container>
                <Styled.Wrapper>
                    <Styled.Shadow />
                    <Link to="/" aria-label="Home">
                        <Styled.Logo />
                    </Link>
                    <Styled.MenuButton onClick={() => setIsOpen(true)} aria-label="Open menu">
                        <Styled.Burger />
                    </Styled.MenuButton>
                </Styled.Wrapper>
            </Styled.Container>
            <Styled.Drawer expanded={isOpen}>
                <Styled.DrawerContent>
                    <Styled.CloseButton onClick={() => setIsOpen(false)} aria-label="Close menu">
                        <Styled.Cross />
                    </Styled.CloseButton>

                    <Styled.Navigation>
                        <Styled.Link as="a" href="#contact-us" onClick={() => setIsOpen(false)}>
                          Partnership inquiries
                        </Styled.Link>

                        <Styled.Link as="a" href="#publish" onClick={() => setIsOpen(false)}>Publish your content</Styled.Link>
                    </Styled.Navigation>

                    <Styled.Text>Get App</Styled.Text>

                    <Styled.StoreButton as="a" href='http://apps.apple.com/us/app/id6498713494?mt=8'>
                        <Styled.AppleStore fill="#0A0A0F" />
                        App Store
                    </Styled.StoreButton>
                    <Styled.StoreButton as="a" href='https://play.google.com/store/apps/details?id=com.dashdramas&hl=en&gl=US'>
                        <Styled.GoogleStore />
                        Google Play
                    </Styled.StoreButton>
                </Styled.DrawerContent>
                <Styled.DrawerShadow />
            </Styled.Drawer>
        </>
    )
}