import React from "react";

import * as Styled from './styled'

export const Publish = () => {
    return (
        <Styled.Container id="publish">
            <Styled.StarIcon />
            <Styled.TopContainer>
                <Styled.Heading>Already have a vertical series and want to show it to the World</Styled.Heading>
                <Styled.ContentWrapper>
                  <Styled.Description>My Drama is a leader for Vertical Series platforms in the market and with us you can be sure your content is going to be seen by millions of users. So, if you have Romance/Drama/Soapy/Sexy Vertical Series make sure to press the button below and do not miss a chance to show your content to the world!</Styled.Description>
                  <a href="mailto:partnerships@my-drama.com">
                    <Styled.Button>Publish your content</Styled.Button>
                  </a>
                </Styled.ContentWrapper>
            </Styled.TopContainer>
            <Styled.Banner alt="My Drama app" src="/img/puplish-content-img.webp" />
        </Styled.Container>
    )
}