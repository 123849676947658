import React from "react";

import * as Styled from './styled';

export const TopStories = () => (
    <Styled.Container>
        <Styled.Title>Our Top Stories</Styled.Title>
        <Styled.ImagesContainer>
            <Styled.BlurBackground />
            <Styled.ImageWrapper>
                <Styled.Image alt='The shy beauty and the billionaire beast' src="/img/top-series-1.webp" />
            </Styled.ImageWrapper>
            <Styled.ImageWrapper>
                <Styled.Image alt="Alpha king's hated princess" src="/img/top-series-2.webp" />
            </Styled.ImageWrapper>
            <Styled.ImageWrapper>
                <Styled.Image alt='Love captive to the mafia boss' src="/img/top-series-3.webp" />
            </Styled.ImageWrapper>
            <Styled.ImageWrapper>
                <Styled.Image alt='Mate, mine' src="/img/top-series-4.webp" />
            </Styled.ImageWrapper>
            <Styled.ImageWrapper>
                <Styled.Image alt="The alpha's mate who cried wolf" src="/img/top-series-5.webp" />
            </Styled.ImageWrapper>
            <Styled.ImageWrapper>
                <Styled.Image alt='Spank me tenderly' src="/img/top-series-6.webp" />
            </Styled.ImageWrapper>
            <Styled.ImageWrapper>
                <Styled.Image alt="In love with my godfather's daughter" src="/img/top-series-7.webp" />
            </Styled.ImageWrapper>
            <Styled.ImageWrapper>
                <Styled.Image alt='Cleopatra' src="/img/top-series-8.webp" />
            </Styled.ImageWrapper>
        </Styled.ImagesContainer>
    </Styled.Container>
)