import styled from "styled-components";

export const Container = styled.section`
    padding-top: 170px;
    padding-bottom: 124px;

    @media screen and (max-width: 1024px) {
      padding-top: 124px;
    }
`;

export const Heading = styled.h2`
  font-weight: 700;
  font-size: 72px;
  line-height: 80px;
  letter-spacing: -0.04em;
  color: #ECECEC;
  margin-bottom: 80px;
  align-self: flex-start;

  @media screen and (max-width: 800px) {
      font-size: 48px;
      line-height: 112%;
      margin-bottom: 50px;
  }

  @media screen and (max-width: 550px) {
      margin-bottom: 36px;
      width: 100%;
      font-size: 38px;
      line-height: 112%;
  }
`;

export const FeaturesWrapper = styled.div`
  width: 100%;
  display: flex;
  gap: 25px;

  @media screen and (max-width: 1024px) {
      flex-direction: column;
      align-items: center;
      height: fit-content;
      gap: 48px;
  }
`;

export const Feature = styled.div`
    flex-grow: 1;
    width: 30%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    @media screen and (max-width: 1024px) {
        width: 100%;
        max-width: 615px;
    }
`;

export const FeatureImageContainer = styled.div`
    border-radius: 20px 20px 0px 0px;
    width: 100%;
    background: ${({ bg }) => bg};
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
    padding-top: 78px;
    height: 550px;
    overflow-x: hidden;
    min-height: 550px;

    @media screen and (max-width: 1024px) {
      align-items: center;
    }

    @media screen and (max-width: 550px) {
        padding-top: 48px;
    }
`;

export const FeatureImage = styled.img`
    height: 100%;
    transform: translateX(50px);

    @media screen and (max-width: 1024px) {
      transform: translateX(0);
    }
`;

export const FeatureTextContainer = styled.div`
    padding: 40px 24px;
    border-radius: 0px 0px 20px 20px;
    background: #15151A;
    height: 100%;

    @media screen and (max-width: 1024px) {
        padding: 32px 20px;        
    }
`;

export const FeatureHeading = styled.h3`
    margin-bottom: 16px;

    font-weight: 700;
    font-size: 34px;
    line-height: 1.4;
    letter-spacing: -0.03em;
    color: #ECECEC;

    @media screen and (max-width: 550px) {
        margin-bottom: 20px;
        font-size: 28px;
        line-height: 128%;
    }
`;

export const FeatureDescription = styled.p`
    font-size: 19px;
    line-height: 32px;
    color: #ECECEC;

    @media screen and (max-width: 550px) {
        font-size: 16px;
        line-height: 150%;
    }
`;